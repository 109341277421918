import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);




export default function NewsChart({newsStats}){
  const counts = newsStats.map(item => item?.newsCount);
  const dates = newsStats.map(item => item.postMonth);
  console.log(newsStats,"sdfhjbcdsjhf")
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false ,
      },
      title: {
        display: true,
        // text: 'Chart.js Line Chart',
      },
    },
  };
  
  const labels = dates
  
  const data = {
    labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data:counts,
        tension: 0.4,

        borderColor: 'rgb(59, 89, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        fill: {
          target: "origin", 
          above: "rgb(59, 89, 132,0.3)"
        }
      }
    ],
  };
  return (
    <div className="App">
        <h2>News</h2>
        <Line options={options} data={data} />;
    </div>
  );
}

