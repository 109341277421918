import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { updateBuilderStatus } from '../../actions/buildingUser';
import { toast } from 'react-hot-toast';

function DeactivateAccountModal({slideData,fetchData,show,onHide}) {
    const handleDeactivate =async()=>{
    
        let status = slideData.status == "1" ? 0 : 1;
        try {
          const response = await updateBuilderStatus(
            {status},
            slideData?.id
          );
          console.log(response,"fbsdjhbsfd")
          fetchData()
          // setBuildingDetail(response?.data?.responseData)
        } catch ({ response, request }) {
          if (response) {
            toast.error(response?.data?.message);
          } else if (request) {
            toast.error("You do not have internet connection");
          }
        }
      }
  return (
    <>

      <Modal show={show} onHide={onHide} dialogClassName="modal-right">
        <Modal.Header closeButton>
          <Modal.Title> {slideData.status == "1" ? "Deactivate Account" : "Activate Account"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to {slideData.status == "1" ? "Deactivate Account" : "Activate Account"}!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            No
          </Button>
          <Button variant="primary" onClick={handleDeactivate}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeactivateAccountModal;