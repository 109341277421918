import { useEffect, useState } from "react";
import { isAuthenticated } from "../../utilities";
import { useNavigate } from "react-router-dom";
import UserGraph from "./UserGraph";
import Tenent from "./Tenent";
import EventChart from "./EventChart";
import NewsChart from "./NewsChart";
import TicketChart from "./TicketChart";
import { getSystemDetails } from "../../actions/dashboard";
import toast from "react-hot-toast";
import { _ } from "../../locale";

function Dashboard() {
  const navigate = useNavigate();
  const [systemGraphData,setSystemGraphData] = useState({})

  useEffect(() => {
    if (!isAuthenticated(localStorage.getItem("jwtToken"))) {
      navigate("/");
    }
  }, []);

  const fetchSystemDetails = async () =>{
    try {
      const resp =  await getSystemDetails()
      console.log(resp,"ewfygcewyugce")
      setSystemGraphData(resp?.data?.responseData)
    } catch ({response,request}) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  }

  useEffect(()=>{
    fetchSystemDetails()
  },[])

  const userJson={
    "totalUsers": 347,
    "totalStaff": 22,
    "totalAdmin": 12,
    "totalActiveSubscriptions": 77
  }
  
  console.log(userJson,"bcdshjbcvds")

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">Dashboard</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="javascript: void(0);">Dashboards</a></li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>
            </div>
            
            <div className="grapgh-stats">
              

                  <div className="card p-3  userStats-card">
                    {
                      systemGraphData && systemGraphData?.userStats &&   <UserGraph userStats={systemGraphData?.userStats} />
                      }
                  </div>

                  <div className="card p-3  userStats-card">
                    {
                      systemGraphData && systemGraphData?.tenentStats &&  <Tenent tenentStats={systemGraphData?.tenentStats} />
                      }
                  </div>

                  <div className="card p-3  userStats-card">
                    {
                        systemGraphData && systemGraphData?.eventStats &&   <EventChart eventStats={systemGraphData?.eventStats} />
                      }
                  </div>

                  <div className="card p-3  userStats-card">
                    {
                      systemGraphData && systemGraphData?.newsStats &&   <NewsChart newsStats={systemGraphData?.newsStats} />
                      }
                  </div>

                  <div className="card p-3  userStats-card">
                    {
                      systemGraphData && systemGraphData?.ticketStats  &&   <TicketChart ticketStats={systemGraphData?.ticketStats} />
                      }
                  </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
