import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { editRole, getPermissions, getRoleById } from "../../actions/acl";
import {_} from "../../locale";
import Inputfield from "../../components/FormFields/InputField";
import MultiSelectCheckboxField from "../../components/FormFields/MultiSelectCheckboxField";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";

function EditRole({slideData,fetchData,show,onHide}) {
  // use hooks
  const { control, handleSubmit, setError, setValue, reset, formState: { isSubmitting } } = useForm();
  const id = slideData?.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState(null);
  let activePage = 1;
  // on form submissions
  const onSubmit = async (formData) => {
    try {
      await editRole({...formData}, id);
      fetchData(activePage);
      onHide();
      navigate('/dashboard/roles');
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  };

   const fetchPermissions = useCallback( async () => {
    try {
      const response = await getPermissions();
      console.log('3wwww', response);
      setPermissions(response?.data?.responseData);
    } catch ({response, request}) {
      // console.log(error, '====');
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  },[]);

   const fetchRecords = useCallback(async () => {
    try {
      let resp = await getRoleById(id);
      let obj = resp?.data?.responseData;
      let reqPerm = [];
      let perm = obj?.Permissions.map(x => reqPerm.push(x.id))
      setValue('title', obj?.title ?? null)
      setValue('permissions', reqPerm)
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, setValue, id]);

  useEffect(() => {
    fetchPermissions();
    fetchRecords();
  }, []);

  console.log('444444', permissions);
  
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      className="right-modal followup-modal max-with-580 addLeadModal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{_('EDIT_ROLE')}</Modal.Title>
      </Modal.Header>
        
      <Modal.Body>
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="mb-3">
                    <label className="form-label">{_('NAME')}</label>
                    <Inputfield
                      control={control}
                      name={"title"}
                      placeholder={_('NAME')}
                      normalize={(e) => e.replace(/^\s+/g, "")}
                      type="text"
                      inputClassName={"form-control"}
                      rules={{
                        required: { value: true, message: _('NAME_REQUIRED') },
                      }}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                    <label className="form-label">{_('PERMISSIONS')}</label>
                    {
                      permissions?.length > 0 && permissions?.map((obj, index) => (
                        <div className="col-12 permision-block">
                          <div className="f-title">
                            <h6 className="text-gray-800 field-title fw-bolder mb-2">{obj?.categoryName}</h6>
                          </div>
                          <MultiSelectCheckboxField
                            control={control}
                            name={`permissions`}
                            label={"Message type"}
                            placeholder="Message Type"
                            options={obj?.permissions ?? []}
                            optionValue="id"
                            optionLabel="name"
                            
                          />
                        </div>
                      ))
                    }
                </div>

                <div className="d-flex flex-wrap gap-2">
                  <button type="submit" disabled={isSubmitting} className={`btn btn-primary waves-effect waves-light ${isSubmitting && "btn-loader"}`}>
                    {_('SAVE')}
                  </button>
                  <Link onClick={onHide} className="btn btn-secondary waves-effect">
                    {_('CANCEL')}
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EditRole;