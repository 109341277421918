import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { _ } from "../../locale";

function LeftNavigation() {
	const [managePanel, setManagePanel] = useState(false);
	const [managePostPanel, setManagePostPanel] = useState(false);
	const [managePagePanel, setManagePagePanel] = useState(false);
	const [manageAccessPanel, setManageAccessPanel] = useState(false);

	// handle management tab
	const handleManagement = () => {
		setManagePanel(prev => !prev);
	};
	const handlePostManagement = () => {
		setManagePostPanel(prev => !prev);
	};
	const handlePageManagement = () => {
		setManagePagePanel(prev => !prev);
	};
	const handleAccessManagement = () => {
		setManageAccessPanel(prev => !prev);
	};

	// handling route activeness
	const isActive = (match, loc, followPath) => {
		if (match) {
			return true;
		} else {
			let path = loc?.pathname?.split('/');
			if (followPath === path?.[2]) {
				return true
			}
		};
	};

	return (
		<div className="vertical-menu">
			<div data-simplebar className="h-100">
				<div id="sidebar-menu">
					<ul className="metismenu list-unstyled" id="side-menu">
						<li className="menu-title" key="t-menu">{_('MENU')}</li>
						<li>
							<NavLink className={({ isActive }) => (isActive ? 'waves-effect active' : 'waves-effect')} to='/dashboard/'>
								<i className="bx bx-home-circle"></i>
								<span key="t-dashboards">{_('Dashboard')} </span>
							</NavLink>
						</li>
						<li>
							<NavLink className={({ isActive }) => (isActive ? 'has-arrow waves-effect active' : 'has-arrow waves-effect')} to='javascript:void(0)' onClick={handleManagement}>
								<i className="bx bx-store"></i>
								<span key="t-ecommerce">{_('Management')}</span>
							</NavLink>

							{
								managePanel === true
								?
								<ul className={managePanel === true ? "sub-menu mm-collapse mm-show" : "sub-menu mm-collapse"} >
									<li>
										<NavLink className={({ isActive }) => (isActive ? 'active' : '')} to='/dashboard/roles'> {_('Roles')}
										</NavLink>
									</li>
									<li>
										<NavLink className={({ isActive }) => (isActive ? 'active' : '')} to='/dashboard/pages'> {_('Static Pages')}
										</NavLink>
									</li>
								</ul>
								:
								null
							}
						</li>
						<li>
							<NavLink className={({ isActive }) => (isActive ? 'waves-effect active' : 'waves-effect')} to='/dashboard/buildingUser'>
								<i className="bx bx-briefcase-alt"></i>
								<span key="t-dashboards">{_('BUILDING_MANAGERS')} </span>
							</NavLink>
						</li>
						{/* <li>
							<NavLink className={({ isActive }) => (isActive ? 'waves-effect active' : 'waves-effect')} to='/dashboard/buildingDetails'>
								<i className="bx bx-home-circle"></i>
								<span key="t-dashboards">{_('BUILDING_DETAILS')} </span>
							</NavLink>
						</li> */}


						

					</ul>
				</div>
			</div>
		</div>
	)
}

export default LeftNavigation;               