import Modal from "react-bootstrap/Modal";
import { _ } from "../../locale";
import Inputfield from "../FormFields/InputField";
import { useForm } from "react-hook-form";
import { setBuilderPassword } from "../../actions/buildingUser";
import { toast } from "react-hot-toast";
import { useState } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import PasswordInputfield from "../FormFields/PasswordInputFiled";
function PasswordChangeModal({ fetchData, slideData, show, onHide }) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const [getPassword, setGetPassword] = useState(true);
  const [activePage,setActivePage] = useState(1)

  const onSubmit = async (formData) => {
    try {
      await setBuilderPassword(formData, slideData?.id);
      fetchData(activePage);
      onHide();
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };
  const showPassword = () => {
    setGetPassword(!getPassword);
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      className="right-modal followup-modal max-with-580 addLeadModal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {_("CHNAGE_PASWWORD")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div class="modal-content-view">
            <div className="left-part">
              <b>#Id</b>
            </div>
            <div className="right-part">
              <p>{slideData?.id}</p>
            </div>
          </div>
          <div class="modal-content-view">
            <div className="left-part">
              <b>Name</b>
            </div>
            <div className="right-part">
              <p>{slideData?.UserProfile?.name}</p>
            </div>
          </div>
          <div class="modal-content-view">
            <div className="left-part">
              <b>Email</b>
            </div>
            <div className="right-part">
              <p>{slideData?.email}</p>
            </div>
          </div>
          <div class="modal-content-view">
            <div className="left-part">
              <b>Phone Number</b>
            </div>
            <div className="right-part">
              <p>
                {slideData?.UserProfile?.countryCode}{" "}
                {slideData?.UserProfile?.phoneNumber}
              </p>
            </div>
          </div>
          <div class="modal-content-view">
            <div className="left-part">
              <b>Change Password</b>
            </div>
            <div className="right-part">
              <p className="position-relative ">
                <div className="input-group auth-pass-inputgroup">
                  <PasswordInputfield
                    control={control}
                    name={"password"}
                    label={_("PASSWORD")}
                    placeholder={_("PASSWORD")}
                    normalize={(e) => e.replace(/^\s+/g, "")}
                    type={getPassword == true ? "password" : "input"}
                    inputClassName={"form-control"}
                    showPassword={showPassword}
                    rules={{
                      required: {
                        value: true,
                        message: _("PASSWORD_REQUIRED"),
                      },
                      minLength: {
                        value: 4,
                        message: _("PASSWORD_VALIDATE"),
                      },
                    }}
                  />
                
                </div>
              </p>
            </div>
          </div>
          <div class="modal-content-view">
            <div className="left-part">{/* <b>Change Password</b> */}</div>
            <div className="right-part-btns">
              <p>
                <button type="submit" className="btn btn-dark">
                  {isSubmitting && (
                    <Spinner animation="grow" variant="secondary" />
                  )}
                  {!isSubmitting && _("save")}
                </button>
              </p>
              <p>
                <button type="button" onClick={onHide} className="btn btn-danger">
                  Cancel
                </button>
              </p>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default PasswordChangeModal;
