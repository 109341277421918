import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { updateBuilderStatus } from "../../actions/buildingUser";
import { useState } from "react";
import { _ } from "../../locale";
import Form from "react-bootstrap/Form";
import PasswordChangeModal from "../../components/Modal/PasswordChangeModal";
import UserBuildingModal from "../../components/Modal/UserBuildingModal";


function BuildingUserSlide({ slideData, index, srno, fetchData }) {
  const [status, setStatus] = useState(slideData?.status);
  const [isChecked, setIsChecked] = useState(slideData.status == "1");
  const[show,setShow] = useState(false)
  const[buildingShow,setBuildingShow] = useState(false)

  const updateStatus = () => {
    let status = slideData.status == "1" ? 0 : 1;
    let chkStatus = slideData.status == "1" ? 0 : 1;
    confirmAlert({
      title: "Confirm",
      message:
        slideData.status == "1"
          ? _("CONFIRM_DEACTIVATE")
          : _("CONFIRM_ACTIVATE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              const response = await updateBuilderStatus(
                { status },
                slideData?.id
              );
              fetchData();
            } catch (error) {
              console.log(error);
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const handleModalShow=()=>{
    setShow(true)
  }
  const handleBuildingModalShow =()=>{
    setBuildingShow(true)
  }

  return (
    <tr>
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td>
        <Link onClick={handleBuildingModalShow}>  {slideData?.UserProfile?.name ?? null}</Link>  
      </td>
      <td>{slideData?.email ?? null}</td>
    
      <td>
        <p className="font-size-14 mb-1">
          {slideData?.UserProfile?.countryCode ?? null}{" "}
          {slideData?.UserProfile?.phoneNumber ?? null}
        </p>
      </td>
      <td className="text-center">{slideData?.totalBuildings ?? null }</td>
      <td>
        {
          <Form.Check
            type="switch"
            id={`custom-switch-${slideData.id}`}
            onChange={() => updateStatus(slideData.id)}
            checked={isChecked}
          />
        }
      </td>

      <td>
        <ul className="list-unstyled hstack gap-1 mb-0">
        <li>
            <Link onClick={handleBuildingModalShow} className="btn btn-sm btn-soft-info" title="Update Password">
              View
            </Link>
          </li>
          <li>
            <Link onClick={handleModalShow} className="btn btn-sm btn-soft-info" title="Update Password">
              <i className="mdi mdi-pencil-outline"></i>
            </Link>
          </li>
        </ul>
      </td>
      {
      show&&  <PasswordChangeModal slideData={slideData} show={show} onHide={()=>{setShow(false)}}/>
      }

      {
        buildingShow && <UserBuildingModal fetchData={fetchData} slideData={slideData} show={buildingShow} onHide={()=>{setBuildingShow(false)}} />
      }
    </tr>
    
  );
}

export default BuildingUserSlide;
