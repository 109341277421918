import { Link } from "react-router-dom";
import UserInfo from './UserInfo';

function Header() {
  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src="/assets/images/logo.svg" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src="/assets/images/Mono_logo_white.svg" alt="" height="17" />
               </span>
            </Link>
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src="/assets/svg/Favicon1.png" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src="/assets/svg/Mono_logo_white.svg" alt="Wallt" />
              </span>
            </Link>
          </div>

          {/* <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
            <i className="fa fa-fw fa-bars"></i>
          </button> */}
        </div>

        <div className="d-flex">
          <div className="dropdown d-inline-block">
            <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="bx bx-bell "></i>
              {/*<span className="badge bg-danger rounded-pill">3</span>*/}
            </button>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown">
              <div className="p-3">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="m-0" key="t-notifications"> Notifications </h6>
                  </div>
                  <div className="col-auto">
                      <a href="#!" className="small" key="t-view-all"> View All</a>
                  </div>
                </div>
              </div>
              <div data-simplebar style={{maxHeight: "230px"}}>
                <a href="javascript: void(0);" className="text-reset notification-item">
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="bx bx-cart"></i>
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-1" key="t-your-order">Your order is placed</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1" key="t-grammer">If several languages coalesce the grammar</p>
                        <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span></p>
                      </div>
                    </div>
                  </div>
                </a>    
              </div>
              <div className="p-2 border-top d-grid">
                <a className="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                  <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View More..</span> 
                </a>
              </div>
            </div>
          </div>

          <UserInfo />

          <div className="dropdown d-inline-block">
            <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
              <i className="bx bx-cog"></i>
            </button>
          </div>

        </div>
      </div>
  </header>
  );
}

export default Header;