import {useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import {_} from "../../locale";
import { getBuildingUserListing } from "../../actions/buildingUser";
import BuildingUserSlide from "./BuildingUserSlide";
import { limit } from "../../constants";
import BuildingUserSkelton from "./BuildingUserSkeleton";
import AddBuildingUserModal from "../../components/Modal/AddBuildingUserModal";

function BuildingUser() {
  // local variables
  const [activePage, setActivePage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [buildingUserData, setBuildingUserData] = useState([])
  const[showUserModal,setShowUserModal] = useState(false)

  const fetchData = async(pageNumber, type) => {
    setShowLoader(true);
    try {
      const resp = await getBuildingUserListing({pageNumber});
      setBuildingUserData(resp?.data?.responseData)
      setShowLoader(false);
    } catch ({response, request}) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
      setShowLoader(false);
    }
  };

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  const handleSetShowUser=()=>{
    setShowUserModal(true)
  }

  useEffect(() => {
    fetchData(activePage);
  }, [activePage]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">{_('BUILDINGS')}</h4>
              <div className="flex-shrink-0">
                <Link onClick={handleSetShowUser} className="btn btn-dark">{_('ADD_BUILDING_MANAGERS')}</Link>
            </div>
            </div>
          </div>
        </div>                        

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{width: "70px"}}>#</th>
                        <th scope="col">{_('NAME')}</th>
                        <th scope="col">{_('EMAIL')}</th>
                        <th scope="col">{_('MOBILE')}</th>
                        <th scope="col">{_('TOTAL_BUILDINGS')}</th>
                        <th scope="col">{_('STATUS')}</th>
                        <th scope="col">{_('ACTION')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        showLoader == true
                        ?
                        <BuildingUserSkelton count={8}/>
                        :
                        (
                          buildingUserData && buildingUserData?.data && buildingUserData?.data?.length > 0 
                          ?
                          buildingUserData && buildingUserData?.data && buildingUserData?.data?.length > 0 && buildingUserData?.data?.map((obj, index) => (
                            <BuildingUserSlide slideData={obj} key={obj.id} index={index} srno={(activePage - 1) * limit}  fetchData={fetchData}/>
                          ))
                          :
                          <tr>
                            <td colspan="7">
                              <h5 className="text-truncate mb-4 mb-lg-5">No Record exist</h5>
                            </td>
                          </tr>
                        )
                      } 
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
      {
      showUserModal && <AddBuildingUserModal fetchData={fetchData} show={showUserModal} onHide={()=>{setShowUserModal(false)}}/>
    }
    </div>
  
  );
}
export default BuildingUser;
