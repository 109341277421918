// API end points
// export const BASE_URL = "http://54.176.169.179:3009";
export const BASE_URL = process.env.REACT_APP_API_URL;

export const limit = 20; 
export const PAGE_LIMIT = 20;
export const imagesToAccept = [".jpg", ".jpeg", ".png", ".gif", ".svg"];
export const VIDEO_MAX_SIZE = 8;
// Field validations
export const errorType = [
  "manual",
  "required",
  "pattern",
  "validate",
  "minLength",
  "maxLength",
  "max",
  "min",
  "positive",
  "lessThanTen",
  "greaterThan",
  "checkUrl",
];

export const POST_TYPE = [
  { 'label': 'Post', 'value': 'post'},
  { 'label': 'Page', 'value': 'page'}
];

export const CONFIG = {
  deviceType: 'WEB',
  contentLanguage: 'en'
}

export const SUBSCRIPTION_PLAN = [
  { 'label': 'razorpay', 'value': 'razorpay'}
];