import { PERMISSION_LISTING, PERMISSION_DETAIL, DELETE_PERMISSION, PERMISSION_STATUS, ROLE_LISTING, ROLE_DETAIL, DELETE_ROLE, ROLE_STATUS} from "../actions/acl";

const initialState = {
  permissionListing: null,
  permissionDetail: null,
  roleListing: null,
  roleDetail: null
};

const acl = (state = initialState, action) => {

  console.log('------', action);

  switch (action.type) {
    case PERMISSION_LISTING:
      return {
        ...state,
        permissionListing: action.payload
      };
    break;
    case PERMISSION_DETAIL:
      return {
        ...state,
        permissionDetail: action.payload
      };
    break;
    case DELETE_PERMISSION:
      const data = { ...state.permissionListing };
      let index = data?.data?.findIndex((item) => item.id === action.id);
      data?.data?.splice(index, 1);
      return {
        ...state,
        permissionListing: data,
      };
    break;
    case PERMISSION_STATUS:
      let perm = { ...state.permissionListing };
      let endex = perm?.data?.findIndex((item) => item.id === action?.payload?.id);
      perm.data[endex] = {...perm.data[endex], ...action.payload}
      return {
        ...state,
        permissionListing: {...perm},
      };
    break;

    case ROLE_LISTING:
      return {
        ...state,
        roleListing: action.payload
      };
    break;
    case ROLE_DETAIL:
      return {
        ...state,
        roleDetail: action.payload
      };
    break;
    case DELETE_ROLE:
      const staffRole = { ...state.roleListing };
      let fndex = staffRole?.data?.findIndex((item) => item.id === action.id);
      staffRole?.data?.splice(fndex, 1);
      return {
        ...state,
        roleListing: staffRole,
      };
    break;
    case ROLE_STATUS:
      let role = { ...state.roleListing };
      let gndex = role?.data?.findIndex((item) => item.id === action?.payload?.id);
      role.data[gndex] = {...role.data[gndex], ...action.payload}
      return {
        ...state,
        roleListing: {...perm},
      };
    break;

    default:
  }
  return state;
}
export default acl;