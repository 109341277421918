import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { _ } from "../../locale";
import Inputfield from "../../components/FormFields/InputField";
import Spinner from "react-bootstrap/esm/Spinner";
import ReactPhoneInput from "../../components/FormFields/ReactPhoneInput";
import {createBuildingUser} from "../../actions/buildingUser";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import {useState} from "react";

function AddBuildingUserModal({fetchData,show,onHide}) {
    const [activePage, setActivePage] = useState(1);
    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
      } = useForm({
      });
      const navigate = useNavigate();
    
    //  console.log(typeOf)
      // on form submissions
      const onSubmit = async (formData) => {
        const payload ={
            ...formData,
            countryCode: '+'+formData.countryCode
        }
    
        try {
        console.log(payload,"dfvchbdfbhvdf")
          await createBuildingUser(payload)
          fetchData(activePage)
          onHide()
          navigate("/dashboard/buildingUser");
        } catch ({ response, request }) {
          if (response) {
            toast.error(response?.data?.message);
          } else if (request) {
            toast.error("You do not have internet connection");
          }
        }
      };
    
    
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-580 addLeadModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{_('ADD_BUILDING_USER')}</Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
        <div className="container-fluid">
              <div className="card">
                <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">{_("NAME")+'*'}</label>
                      <Inputfield
                        control={control}
                        name={"name"}
                        placeholder={_("NAME")}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: {
                            value: true,
                            message: _("NAME_REQUIRED"),
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="mb-3">
                      <label className="form-label">{_("EMAIL")+'*'}</label>
                      <Inputfield
                        control={control}
                        name={"email"}
                        placeholder={_("Email")}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="email"
                        inputClassName={"form-control"}
                        rules={{
                          required: {
                            value: true,
                            message: _("Email Required"),
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="">
                    <div className="mb-3">
                      <label className="form-label">{_("USERNAME")+'*'}</label>
                      <Inputfield
                        control={control}
                        name={"username"}
                        placeholder={_("USERNAME")}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: {
                            value: true,
                            message: _("USERNAME_REQUIRED"),
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="">
                    <div className="mb-3">
                      <ReactPhoneInput
                          label="Phone Number*"
                          placeholder={_("phone")}
                          control={control}
                          defaultCountry={"us"}
                          countryCodeValue={{
                            countryCodeValue: "",
                            defaultCountryCodeValue: "",
                          }}
                          inputClass="neWInput-Class"
                          countryCode={{
                            countryCodeName: "countryCode",
                            countryCodeRules: {
                              required: {
                                value: true,
                              },
                            },
                            defaultValue: "",
                          }}
                          phoneNumber={{
                            phoneName: "phoneNumber",
                            phoneType: "text",
                            phoneRules: {
                              required: {
                                value: true,
                                message: _("PHONE_REQUIRED"),
                              },
                              minLength: {
                                value: 10,
                                message: _("PHONE_REQUIRED_LENGTH"),
                              },
                              maxLength: {
                                value: 16,
                                message: _("PHONE_MIN_LENGTH"),
                              },
                            },
                            phoneDefaultValue: "",
                          }}
                        />
                      </div>
                      </div>

                

                  <div className="">
                    <div className="mb-3">
                      <label className="form-label">{_("PASSWORD")+'*'}</label>
                      <Inputfield
                        control={control}
                        name={"password"}
                        placeholder={_("Password")}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="password"
                        inputClassName={"form-control right"}
                        rules={{
                          required: {
                            value: true,
                            message: _("PASSWORD_REQUIRED"),
                          },
                        }}
                      />
                    </div>
                  </div>


                  <div className="d-flex flex-wrap gap-2">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-dark waves-effect waves-light ${
                        isSubmitting && "btn-loader"
                      }`}
                    >
                      {isSubmitting &&  <Spinner animation="grow" variant="secondary" />}
                    {!isSubmitting &&   _("ADD_BUILDING")}
                    </button>
                    <Link onClick={onHide} className="btn btn-secondary waves-effect">
                      {_("CANCEL")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddBuildingUserModal;
