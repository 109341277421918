import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const PersonalDetailSkleton = () => {
  return (
    <div className="personal-details">
      <div className="personal-details-title">
        <Skeleton width={"150px"} height={"25px"} />
      </div>
      <div className="user-details">
        <div className="details-image-conatiner">
          <figure className="image-size">
            <Skeleton width={"250px"} height={"250px"} />
          </figure>
        </div>

        {
          <div className="user-details-container">
            <ul className="user-information">
              <li className="d-flex gap-3">
                <Skeleton
                  className="heading-tag"
                  width={"70px"}
                  height={"20px"}
                />
                <Skeleton
                  className="content-info"
                  width={"200px"}
                  height={"20px"}
                />
              </li>
              <li className="d-flex gap-3">
                <Skeleton
                  className="heading-tag"
                  width={"70px"}
                  height={"20px"}
                />
                <Skeleton
                  className="content-info"
                  width={"200px"}
                  height={"20px"}
                />
              </li>
              <li className="d-flex gap-3">
                <Skeleton
                  className="heading-tag"
                  width={"70px"}
                  height={"20px"}
                />
                <Skeleton
                  className="content-info"
                  width={"200px"}
                  height={"20px"}
                />
              </li>
              <li className="d-flex gap-3">
                <Skeleton
                  className="heading-tag"
                  width={"70px"}
                  height={"20px"}
                />
                <Skeleton
                  className="content-info"
                  width={"200px"}
                  height={"20px"}
                />
              </li>
            </ul>
          </div>
        }
      </div>
    </div>
  );
};

export default PersonalDetailSkleton;
