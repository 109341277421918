import React, { useState } from "react";
import { _ } from "../../locale";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
// import { changePassword } from "../../actions/auth";
import { useNavigate } from "react-router-dom";
import Inputfield from "../../components/FormFields/InputField";
function Profile() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const showPassword = () => {
    setGetPassword(!getPassword);
  };

  const [getPassword, setGetPassword] = useState(true);

  const onSubmit = async (formState) => {
    if (formState.newPassword !== formState.confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      // await changePassword({
      //   oldPassword: formState.oldPassword,
      //   newPassword: formState.newPassword,
      // })
      //   .then((res) => {
      //     console.log("response", res);
      //     reset();
      //     toast.success("Password updated successfully!");
      //     if (res) {
      //       navigate("/dashboard");
      //     }
      //   })

      //   .catch((error) => {
      //     console.log(error);
      //     toast.error("Error updating password");
      //     console.error(error);
      //   });
    }
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">{_("PROFILE SETTINGS")}</h4>
              <div className="flex-shrink-0"></div>
            </div>
          </div>
        </div>
        <form
          style={{ width: "65%", margin: "4rem auto", padding: "1rem" }}
          class="bg-white"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h4 className="mb-sm-0 font-size-18" class="text-center">
            {_("RESET PASSWORD")}
          </h4>

          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Old Password
            </label>
            <Inputfield
              control={control}
              name={"oldPassword"}
              label={_('PASSWORD')}
              placeholder={_('Old Password')}
              normalize={(e) => e.replace(/^\s+/g, "")}
              type={"text"}
              inputClassName={"form-control"}
              rules={{
                required: { value: true, message: _('PASSWORD_REQUIRED') },
                minLength: {
                  value: 4,
                  message: _('PASSWORD_VALIDATE'),
                },
              }}
            />
          </div>

          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              New Password
            </label>
            <Inputfield
              control={control}
              name={"newPassword"}
              label={_('PASSWORD')}
              placeholder={_('Old Password')}
              normalize={(e) => e.replace(/^\s+/g, "")}
              type={"text"}
              inputClassName={"form-control"}
              rules={{
                required: { value: true, message: _('PASSWORD_REQUIRED') },
                minLength: {
                  value: 4,
                  message: _('PASSWORD_VALIDATE'),
                },
              }}
            />
          </div>

          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Confirm Password
            </label>
            <Inputfield
              control={control}
              name={"confirmPassword"}
              label={_('PASSWORD')}
              placeholder={_('Old Password')}
              normalize={(e) => e.replace(/^\s+/g, "")}
              type={"text"}
              inputClassName={"form-control"}
              rules={{
                required: { value: true, message: _('PASSWORD_REQUIRED') },
                minLength: {
                  value: 4,
                  message: _('PASSWORD_VALIDATE'),
                },
              }}
            />
          </div>

          <input
            type="submit"
            value="Change Password"
            class="mt-3 btn btn-primary"
          />
        </form>
      </div>
    </div>
  );
}

export default Profile;
