export const locale = {
    //start english
    en: {
        'SIGN_IN_CONTINUE': 'Sign in to continue to Mono Space',
        'CRAFTED_WITH': 'Invoicely. Crafted with',
        'BY_ILLUMINZ': 'by Illuminz',
        'NO_INTERNET': 'You do not have internet connection',
        'EMAIL': 'Email',
        'EMAIL_REQUIRED': 'Email is required',
        'EMAIL_VALID': 'Please enter valid email address',
        'PASSWORD': 'Password',
        'PASSWORD_REQUIRED': 'Password is required',
        'PASSWORD_VALIDATE': 'Password must be more than four characters',
        'LOGIN': 'Log In',
        'PROJECTS': 'Projects',
        'NAME': 'Name',
        'DESCRIPTION': 'Description',
        'ACTION': 'Action',
        'ADD_MILESSTONE_ITEAM': 'ADD MILESSTONE ITEAM',
        'ADD': 'Add',
        'CATEGORY_TYPE_INFO': 'Set basic info for category type',
        'TITLE': 'Title',
        'TITLE_REQUIRED': 'Title is equired',
        'DESCRIPTION': 'Description',
        'DESCRIPTION_REQUIRED': 'Description is required',
        'SAVE': 'Save',
        'CANCEL': 'Cancel',
        'STATUS': 'Status',
        'ACTIVE': 'Active',
        'NOT_ACTIVE': 'Not Active',
        'EDIT_CATEGORY_TYPE': 'Edit Category Type',
        'EDIT': 'Edit',
        'CATEGORY': 'Category',
        'ADD_CATEGORY': 'Add Category',
        'IMAGE':'Image',
        'PARENT_CATEGORY':'Parent Category',
        'CONFIRM_DELETE':'Are you sure to delete this record?',
        'YES':'Yes',
        'NO': 'No',
        'CONFIRM_DEACTIVATE': 'Are you sure to deactivate this record?',
        'CONFIRM_ACTIVATE': 'Are you sure to activate this record?',
        'CATEGORY_INFO': 'Set basic info for category',
        'Menu': 'Menu',
        'MANAGEMENT':'Management',
        'DASHBOARD':'Dashboard',
        'MILESSTONE_ITEAMS':'MilesStone Iteams',
        'ADD_EMAIL':'Add Email',
        'CODE':'Code',
        'SUBJECT': 'Subject',
        'EMAIL_TEMPLATE_INFO': 'Set basic info for email template',
        'SUBJECT':'Subject',
        'SUBJECT_REQUIRED':'Subject is required',
        'CODE':'Code',
        'CODE_REQUIRED':'Code is required',
        'REPLACEMENTS':'Replacements',
        'REPLACEMENTS_REQUIRED':'Replacements is required',
        'PLAN': 'Plan',
        'PLAN_LISTING': 'Plan listing',
        'CREATE_PLAN': 'Create Plan',
        'PLAN_INFO': 'Set basic info for plan',
        'NAME_REQUIRED': 'Name is required',
        'PAYMENT_GATEWAY': 'Payment gateway',
        'PAYMENT_GATEWAY_REQUIRED': 'Payment gateway name is required',
        'PLAN_ID': 'Plan id',
        'PLAN_ID_REQUIRED': 'Plan id is required',
        'DATE': 'Date',
        'POST': 'Post',
        'POST_LISTING': 'Post listing',
        'CREATE_POST': 'Create post',
        'POST_INFO': 'Set basic info for post',
        'EXCERPT': 'Excerpt',
        'EXCERPT_REQUIRED': 'Excerpt is required',
        'POST_TYPE': 'Post type',
        'POST_TYPE_REQUIRED': 'Post type is required',
        'CREATED_BY': 'Created by',
        'UPDATED_BY': 'Updated by',
        'BY': 'By:',
        'PAGE': 'Page',
        'EDIT_POST': 'Edit post',
        'PAGE_LISTING': 'Page listing',
        'CREATE_PAGE': 'Create page',
        'PAGE_INFO': 'Set basic info for page',
        'EDIT_PAGE': 'Edit page',
        'VIEW': 'View',
        'SUB_CATEGORY':'Sub category',
        'ACCESS_CONTROL': 'Access control',
        'PERMISSIONS':'Permissions',
        'ADD_PERMISSION': 'Add permission',
        'PERMISSION': 'Permission',
        'POST_CATEGORY': 'Post category',
        'ADD_POST_CATEGORY': 'Add post category',
        'VIEW_SUBCATEGORY': 'View subcategory',
        'EDIT_CATEGORY': 'Edit category',
        'CREATE_PERMISSION': 'Create permission',
        'ADMINONLY':'Admin only',
        'PERMISSION_CODE': 'Permission code',
        'PERMISSION_CODE_REQUIRED': 'Permission code required',
        'PERMISSION_INFO': 'Set basic info for permission',
        'ROLES': 'Roles',
        'ROLE': 'Role',
        'ADD_ROLE': 'Add role',
        'ROLE_INFO': 'Set basic info for role',
        'PAGE_CATEGORY': 'Page category',
        'ADD_PAGE_CATEGORY': 'Add page category',
        'SUBSCRIPTION_PLAN': 'Subscription plan',
        'STORAGE_REQUIRED': 'STORAGE_REQUIRED',
        'STORAGE': 'Storage',
        'MB': 'mb',
        'CLINTS': 'Clints',
        'ADD_DIRECTORY': 'Add directory',
        'VIEW_SUBDIRECTORY': 'View subdirectory',
        'SYSTEM_DEFAULT': 'System default',
        'DIRECTORY_INFO': 'Set basic info for directory',
        'DIRECTORY_IMAGE': 'Directory image',
        'EDIT_DIRECTORY': 'Edit directory',
        'SIGNUP_TO_CONTINUE':'Sigup to continue to mono space',
        'FIRST_NAME_REQUIRED':'First name is required',
        'LAST_NAME_REQUIRED':'Last name is required',
        'CONFIRM_PASSWORD_REQUIRED':'Confirm password is required',
        'BUILDING_USER':'Building user',
        'ADD_BUILDING':'Add building user',
        'BUILDINGS':'Building users',
        'ADD_BUILDING':'Add building user',
        'MOBILE':'Mobile',
        'ADD_BUILDING_USER':'Add building user',
        'CHNAGE_PASWWORD':'Change password',
        'BUILDING':'Building',
        'USERNAME':'Username',
        'USERNAME_REQUIRED':'Username is required',
        'PHONE_REQUIRED':'Phone number is required',
        'BUILDING_DETAILS':'Building Details',
        'BUILDINGS_DETAILS':'Building Details',
        'TOTAL_BUILDINGS':'Total Buildings',
        'PERSONAL_DETAILS':'Personal Details',
        'JOINING_DATE':'Joining Date',
        'PHONE':'Phone',
        'BUILDING_NAME':'Building Name',
        'BUILDING_ADDRESS':'Building Address',
        'USER':'User',
        'TENENT':'Tenent',
        'EVENT':'Event',
        'NEWS':'News',
        'TICKET':'Ticket',
        'BUILDING_MANAGERS':'Building Managers',
        'ADD_BUILDING_MANAGERS':'Add Building Manager',
        "EDIT_ROLE":"Edit role",
        "NO_RECORD_EXIST": "No record exist.",
        "STATIC_PAGES": "Static pages",
        "SLUG": "Slug",
        "ADD_NEW": "Add new",
        "ADD_PAGE":"Add page",
        "EDIT_PAGE": "Edit page"
    },
    //end english

};
