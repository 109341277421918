import Modal from "react-bootstrap/Modal";
import { _ } from "../../locale";
import {
  getBuilderDetails,
} from "../../actions/buildingUser";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../../constants";
import DeactivateAccountModal from "./AccountDeactivateModal";
import PersonalDetailSkleton from "../../pages/Building Managers/PersonalDetailSkleton";
import BuildingDetailSkleton from "../../pages/Building Managers/BuildingDetailSkeleton"
function UserBuildingModal({ fetchData, slideData, show, onHide }) {

  const [buildingDetail, setBuildingDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const fetchBuilderData = async (id) => {
    setIsLoading(true);
    try {
      const resp = await getBuilderDetails(id);
      setBuildingDetail(resp?.data?.responseData);
      setIsLoading(false);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBuilderData(slideData?.id);
  }, [slideData?.id]);

  

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="  right-modal followup-modal  addLeadModal"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {isLoading ? (
              <Skeleton width={"150px"} height={"20px"} />
            ) : (
              <h4 className="mb-sm-0 font-size-18">{_("BUILDINGS_DETAILS")}</h4>
            )}
          </Modal.Title>
          <div className="align-items-center d-flex">
            <div className="details-main-section">
              {isLoading ? (
                <Skeleton width={"100px"} height={"25px"} />
              ) : (
                <div className="details-btn-div">
                  <button
                    className="btn btn-dark"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {slideData.status === 0 ? "Activate" : "Deactivate"}
                  </button>
                </div>
              )}
            </div>
            <div
              className="cross-icon-style"
              onClick={() => {
                onHide();
              }}
            >
              <img src="/assets/svg/cross.png" />
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <div className="flex-shrink-0"></div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="details-main-section">
                  {isLoading ? (
                    <PersonalDetailSkleton />
                  ) : (
                    <div className="personal-details">
                      <div className="personal-details-title">
                        <h3 className="head-title">{_("PERSONAL_DETAILS")}</h3>
                      </div>
                      <div className="user-details">
                        <div className="details-image-conatiner">
                          <figure className="image-size">
                            {buildingDetail?.profile?.UserProfile
                              ?.Attachment !== null ? (
                              <img
                                width={"100%"}
                                height={"100%"}
                                src={`${BASE_URL}/${buildingDetail?.profile?.UserProfile?.Attachment?.path}`}
                              />
                            ) : (
                              <img
                                width={"100%"}
                                height={"100%"}
                                src="https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
                              />
                            )}
                          </figure>
                        </div>

                        {buildingDetail && (
                          <div className="user-details-container">
                            <ul className="user-information">
                              <li className="user-info">
                                <label className="heading-tag">{_("NAME")}</label>
                                <span className="content-info">
                                  {buildingDetail?.profile?.UserProfile?.name}
                                </span>
                              </li>
                              <li className="user-info">
                                <label className="heading-tag">
                                  {_("JOINING_DATE")}
                                </label>
                                <span className="content-info">
                                  {moment(
                                    buildingDetail?.profile?.createdAt
                                  ).format("lll")}
                                </span>
                              </li>
                              <li className="user-info">
                                <label className="heading-tag">{_("EMAIL")}</label>
                                <span className="content-info">
                                  <a href="#">
                                    {buildingDetail?.profile?.email}
                                  </a>
                                </span>
                              </li>
                              <li className="user-info">
                                <label className="heading-tag">{_("PHONE")}</label>
                                <span className="content-info">
                                  <a href="#">
                                    {
                                      buildingDetail?.profile?.UserProfile
                                        ?.country_code
                                    }{" "}
                                    {
                                      buildingDetail?.profile?.UserProfile
                                        ?.phone_number
                                    }
                                  </a>
                                </span>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {isLoading ? (
                    <BuildingDetailSkleton />
                  ) : (
                    <div className="buildings-details">
                      <div className="personal-details-title">
                        {buildingDetail &&
                          buildingDetail?.buildings?.length > 0 && (
                            <h3 className="head-title">{_("BUILDINGS")}</h3>
                          )}
                      </div>
                      {buildingDetail &&
                        buildingDetail?.buildings?.length > 0 &&
                        buildingDetail?.buildings?.map((elm) => {
                          return (
                            <div className="building-details">
                              <div className="buidling-address-detail">
                                <div className="details-image-conatiner">
                                  {console.log(
                                    `${BASE_URL}${elm?.Attachment?.path}`,
                                    "sabcjhasb"
                                  )}
                                  <figure className="image-size">
                                    {elm.Attachment !== null ? (
                                      <img
                                        width={"100%"}
                                        height={"100%"}
                                        src={`${BASE_URL}/${elm?.Attachment?.path}`}
                                      />
                                    ) : (
                                      <img
                                        width={"100%"}
                                        height={"100%"}
                                        src="https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
                                      />
                                    )}
                                  </figure>
                                </div>
                                <div className="user-details-container">
                                  <ul className="user-information">
                                    <li className="user-info">
                                      <label className="heading-tag">
                                        {_("BUILDING_NAME")}
                                      </label>
                                      <span className="content-info">
                                        {elm.name}
                                      </span>
                                    </li>
                                    <li className="user-info">
                                      <label className="heading-tag">
                                        {_("BUILDING_ADDRESS")}
                                      </label>
                                      <span className="content-info">
                                        {elm.address}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="ticket-section">
                                <div className="deatil-section">
                                  <h4 className="deatil-title">{_("USER")}</h4>
                                  <p className="deatil-data">
                                    {elm.stats?.users}
                                  </p>
                                </div>
                                <div className="deatil-section">
                                  <h4 className="deatil-title">{_("TENENT")}</h4>
                                  <p className="deatil-data">
                                    {elm.stats?.tenent}
                                  </p>
                                </div>
                                <div className="deatil-section">
                                  <h4 className="deatil-title">{_("EVENT")}</h4>
                                  <p className="deatil-data">
                                    {elm.stats?.events}
                                  </p>
                                </div>
                                <div className="deatil-section">
                                  <h4 className="deatil-title">{_("NEWS")}</h4>
                                  <p className="deatil-data">
                                    {elm.stats?.news}
                                  </p>
                                </div>
                                <div className="deatil-section">
                                  <h4 className="deatil-title">{_("TICKET")}</h4>
                                  <p className="deatil-data">
                                    {elm.stats?.tickets}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal.Body>
      </Modal>
      {showModal && (
        <DeactivateAccountModal
          show={showModal}
          slideData={slideData}
          fetchData={fetchData}
          onHide={()=>{setShowModal(false)}}
        />
      )}
    </>
  );
}

export default UserBuildingModal;
