import { useController } from "react-hook-form";
import { BASE_URL, errorType } from "../../constants";

function MultiSelectCheckboxField({
  name,
  control,
  rules,
  defaultValue,
  options,
  optionValue,
  onChange,
  errorClass,
  optionLabel,
}) {
  // use hooks
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  //   on Change input
  const onInputChange = (checked, _value) => {
    let newValue;
    if (checked) {
      if (Array.isArray(field.value)) {
        newValue = [...field?.value, _value];
      } else {
        newValue = [_value];
      }
    } else {
      let array = [...field.value];
      let index = array.findIndex((item) => item == _value);
      array.splice(index, 1);
      if (array?.length > 0) newValue = array;
      else newValue = "";
    }

    if (onChange) onChange(newValue);
    field.onChange(newValue);
  };

  console.log('wewe', options);

  return (
    <>
      <div className="inline-field-wrpr">
        <div className={`form-in ${error ? "required-field" : ""}`}>
          <div className="row week-block">
            {options?.map((_option, index) => {
              return (
                <div className="col day-block" key={index}>

                  <div className="form-check">
                    <input
                      id={'gridCheck_'+_option?.id}
                      className="form-check-input"
                      type="checkbox"
                      checked={
                        field?.value?.length &&
                        field?.value?.includes(_option?.[optionValue])
                      }
                      name={`${name}[${index}]`}
                      onChange={(e) =>
                        onInputChange(e.target.checked, _option[optionValue])
                      }
                    />
                    <label className="form-check-label" for={"gridCheck_"+_option?.id}>
                      {optionValue ? _option[`${optionLabel}`] : _option?.name}
                    </label>
                  </div>

                  
                </div>
              );
            })}
          </div>
          {errorType?.map((type) => {
            if (error?.type === type && error?.message !== "")
              return (
                <p key={type} className={errorClass || "error"}>
                  {error?.message}
                </p>
              );
          })}
        </div>
      </div>
    </>
  );
}

/**
 * @property defaults
 */
MultiSelectCheckboxField.defaultProps = {
  defaultValue: "",
  rules: {},
  errorClass: "error",
  onChange: (value) => value,
  optionLabel: "name",
  optionValue: "value",
};

export default MultiSelectCheckboxField;