import axios from "axios";
import {BASE_URL} from "../constants";

export const USER_LISTING = 'USER_LISTING';
// export const PLAN_DETAIL = 'PLAN_DETAIL';
// export const DELETE_PLAN = 'DELETE_PLAN';
// export const PLAN_STATUS = 'PLAN_STATUS';

export const getBuildingUserListing = (props) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/admin/listBuilders`
    });
};

export const createBuildingUser = (payload) => {
    console.log(payload,"dsbcdhjsbc")
    return axios({
        method: 'POST',
        data: payload,
        url: `/admin/createBuilder`
    });
};

export const updateBuilderStatus = (payload,id) => {
    console.log(payload,"dsbcdhjsbc")
    return axios({
        method: 'PATCH',
        data: payload,
        url: `/admin/updateBuilderStatus/${id}`
    });
};

export const setBuilderPassword = (payload,id) => {
    console.log(payload,id,"dsbcdhjsbc")
    return axios({
        method: 'PATCH',
        data: payload,
        url: `/admin/setBuilderPassword/${id}`
    });
};

export const getBuilderDetails = (id) => {
    console.log(id,"dsbchdshjbc")
    return axios({
        method: 'GET',
        // data: payload,
        url: `/admin/getBuilderDetails/${id}`
    });
};

