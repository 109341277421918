import axios from "axios";
import {BASE_URL} from "../constants";

export const PERMISSION_LISTING = 'PERMISSION_LISTING';
export const PERMISSION_DETAIL = 'PERMISSION_DETAIL';
export const DELETE_PERMISSION = 'DELETE_PERMISSION';
export const PERMISSION_STATUS = 'PERMISSION_STATUS';
export const ROLE_LISTING = 'ROLE_LISTING';
export const ROLE_DETAIL = 'ROLE_DETAIL';
export const DELETE_ROLE = 'DELETE_ROLE';
export const ROLE_STATUS = 'ROLE_STATUS';

///category type crud
export const getPermissions = (props) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/admin/getPermissions`
    });
};
export function getPermissionById(id){
    return axios({
        method: 'GET',
        // params: payload,
        url: '/permission/'+id,
    })
}
export const addPermission = (props) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/permission'
    });
};
export function editPermission(payload, id){
    console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/permission/'+id
    })
}
export function deletePermission(id){
    return axios({
        method: 'DELETE',
        url: '/permission/'+id
    })
}
export function updatePermissionStatus(payload, id){
    // console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/permission/'+id+'/status'
    })
}


export const getRoles = (props) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/admin/listRoles`
    });
};
export function getRoleById(id){
    return axios({
        method: 'GET',
        // params: payload,
        url: '/admin/role/'+id,
    })
}
export const addRole = (props) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/admin/createRole'
    });
};
export function editRole(payload, id){
    console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/admin/updateRole/'+id
    })
}
export function deleteRole(id){
    return axios({
        method: 'DELETE',
        url: '/admin/role/'+id
    })
}
export function updateRoleStatus(payload, id){
    // console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/admin/role/'+id+'/status'
    })
}



