import { Route, Routes } from "react-router-dom";
import Header from "../../components/common/Header";
import LeftNavigation from "../../components/common/LeftNavigation";
import Dashboard from "../dashboard/Dashboard";
import Profile from "../profile/Profile";
import BuildingUser from "../Building Managers/BuildingUser";
import PageNotFound from "../Page not found/PageNotFound";
import ManageRoles from "../acl/ManageAclRole";
import ManagePages from "../pages/ManagePages";
import AddPage from "../pages/AddPage";
import EditPage from "../pages/EditPage";

function Layout() {
  return (
    <div id="layout-wrapper">
      <Header />
      <LeftNavigation />

      <div className="main-content">
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="/buildingUser" element={<BuildingUser/>}/>
          <Route path="/roles" element={<ManageRoles/>}/>
          <Route path="/pages" element={<ManagePages />}/>
          <Route path="/pages" element={<ManagePages />}/>
           
          <Route path="/pages/add" element={<AddPage />}/>
          <Route path="/pages/:slug/edit" element={<EditPage />}/>
          
          {/* <Route path="/profile" element={<Profile />} /> */}
          {/* <Route path="*" element={<PageNotFound />} /> */}
        </Routes>
      </div>
    </div>
  );
}

export default Layout;
