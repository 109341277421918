import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function TicketChart({ ticketStats }) {
  const counts = ticketStats.map(item => item?.totalTickets);
  const status = ticketStats.map(item => item.ticketStatus);
  
  const ticketStatusMapping = {
    0: "OPEN",
    1: "IN_PROGRESS",
    2: "OVERDUE",
    3: "CLOSED"
  };

  const labels = status.map(s => ticketStatusMapping[s]);

  // Define colors for each status
  const colors = {
    OPEN: 'rgba(255, 99, 132, 0.5)',
    IN_PROGRESS: 'rgba(54, 162, 235, 0.5)',
    OVERDUE: 'rgba(255, 206, 86, 0.5)',
    CLOSED: 'rgba(75, 192, 192, 0.5)',
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
    barThickness: 50,
    tooltips: {
      filter: function (tooltipItem) {
        return tooltipItem.datasetIndex === 0;
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: counts,
        backgroundColor: labels.map(label => colors[label]),
        borderColor: labels.map(label => colors[label].replace('0.5', '1')), // Optional: Set border color
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="App">
      <h2>Tickets</h2>
      <Bar options={options} data={data} />
    </div>
  );
}
