import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);




export default function EventChart({eventStats}){
  console.log(eventStats,"sdfhsdhbjv")
  const counts = eventStats.map(item => item['count(e.id)']);
  const dates = eventStats.map(item => item.rStartDate);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false ,
      },
      title: {
        display: true,
        // text: 'Chart.js Line Chart',
      },
    },
  };
  
  const labels = dates
  
  const data = {
    labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: counts,
        tension: 0.4,

        borderColor: 'rgb(25, 39, 442)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        fill: {
          target: "origin", 
          above: "rgb(25, 39, 442,0.3)"
        }
      }
    ],
  };
  return (
    <div className="App">
        <h2>Event</h2>
        <Line options={options} data={data} />;
    </div>
  );
}

