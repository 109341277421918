import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {_} from "../../locale";

function ManagePageSlide({fetchData, slideData, type, index, srno}) {
  
  return (
    <>
    <tr>
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td><p className="font-size-14 mb-1">{slideData?.title ?? null}</p></td>
      <td>
        <p className="font-size-14 mb-1 break-spaces">
          {slideData?.slug ?? null}
        </p>
      </td>
      <td>
        <ul className="list-unstyled hstack gap-1 mb-0">
          <li>
              <Link to={`/dashboard/pages/${slideData?.slug}/edit`} className="btn btn-sm btn-soft-info" title="Edit"><i className="mdi mdi-pencil-outline"></i></Link>
          </li>
          
        </ul>
      </td>
    </tr>

    

  </>
  );
}

export default ManagePageSlide;
