import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { addStaticPage } from "../../actions/page";
import {_} from "../../locale";
import Inputfield from "../../components/FormFields/InputField";
import JoditEdior from "../../components/FormFields/JoditEditor";
import { toast } from "react-hot-toast";

function AddPage() {
  // use hooks
  const {control, handleSubmit, setValue, formState: { isSubmitting }, } = useForm();
  const navigate = useNavigate();
  // on form submissions
  const onSubmit = async (formData) => {
    try {
      await addStaticPage({...formData});
      navigate('/dashboard/pages');
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  };
  
  return (
      <div className="page-content">
        <div className="container-fluid">
        
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">{_('ADD_PAGE')}</h4>
              
            </div>
          </div>
        </div>   

        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="mb-3">
                    <label className="form-label">{_('NAME')}</label>
                    <Inputfield
                      control={control}
                      name={"title"}
                      placeholder={_('TITLE')}
                      normalize={(e) => e.replace(/^\s+/g, "")}
                      type="text"
                      inputClassName={"form-control"}
                      rules={{
                        required: { value: true, message: _('TITLE_REQUIRED') },
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3">
                    <JoditEdior
                      control={control}
                      name={"description"}
                      label={_('DESCRIPTION')}
                      errorClass="error-msg"
                      rules={{
                        required: {
                          value: true,
                          message: _('DESCRIPTION_REQUIRED'),
                        },
                      }}
                    />
                  </div>
                </div>

                

                <div className="d-flex flex-wrap gap-2">
                  <button type="submit" disabled={isSubmitting} className={`btn btn-primary waves-effect waves-light ${isSubmitting && "btn-loader"}`}>
                    {_('SAVE')}
                  </button>
                  <Link to="/dashboard/pages" className="btn btn-secondary waves-effect">
                    {_('CANCEL')}
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPage;
