import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-hot-toast";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { PAGE_LIMIT } from "../../constants";
import { getPages } from "../../actions/page";
import {_} from "../../locale";
import ManagePageSlide from './ManagePageSlide';
import RoleSkelton from './RoleSkelton';

function ManagePages() {
  // local variables
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [detail, setDetail] = useState();

  const fetchData = useCallback( async (page) => {
    setShowLoader(true);
    try {
      const resp = await getPages({'pageNumber':page});
      setDetail(resp?.data?.responseData);
      setShowLoader(false);
    } catch ({response, request}) {
      // console.log(error, '====');
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
      setShowLoader(false);
    }
  },[]);

  const handlePageChange = (page) => {
    setActivePage(page);
  };


  useEffect(() => {
    fetchData(activePage);
  }, [fetchData, activePage]);

  let limit = detail?.perPage ?? PAGE_LIMIT;
  return (
    <div className="page-content">
      <div className="container-fluid">
        
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">{_('STATIC_PAGES')}</h4>
              <div className="flex-shrink-0">
                <Link to="/dashboard/pages/add" className="btn btn-dark">{_('ADD_NEW')}</Link>
              </div>

            </div>
          </div>
        </div>                        

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{width: "70px"}}>#</th>
                        <th scope="col">{_('TITLE')}</th>
                        <th scope="col">{_('SLUG')}</th>
                        <th scope="col">{_('ACTION')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        showLoader == true
                        ?
                        <RoleSkelton count={5} />
                        :
                        (
                          detail && detail?.data && detail?.data?.length > 0 
                          ?
                          detail && detail?.data && detail?.data?.length > 0 && detail?.data?.map((obj, index) => (
                            <ManagePageSlide fetchData={fetchData} slideData={obj} key={obj.id} index={index} srno={(activePage - 1) * limit} />
                          ))
                          :
                          <tr>
                            <td colspan="7" className="empty-record-msg">
                              <h5 className="text-truncate mb-4 mb-lg-5">{_('NO_RECORD_EXIST')}</h5>
                            </td>
                          </tr>
                        )
                      }  
                    </tbody>
                    
                  </table>
                </div>
              </div>
            </div>
            <ResponsivePagination
              current={activePage}
              total={detail?.totalPages ?? 1}
              onPageChange={handlePageChange}
            /> 
          </div>
        </div>
      </div> 

    </div>
  );
}
export default ManagePages; 