import axios from "axios";
import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import moment from 'moment';
import { BASE_URL } from "./constants";
import { clearSession } from "./utilities";
import rootReducer from "./reducers";

const persistConfig = {
    key: "root",
    storage,
  };

  console.log(process.env.REACT_API_URL,"dfvbdfhjv")
  
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  
  export const store = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

axios.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("jwtToken");
    console.log(token,"ttoookkeenn")
    // const timezone = moment.tz.guess();
    if (token) {
      request.headers["authorization"] = token;
      // request.headers["language"] = "en";
      // request.headers["timezone"] = 'UTC';
      // request.headers["timezone"] = timezone;
    }
    request.baseURL = process.env.REACT_APP_API_URL;

    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const persistor = persistStore(store);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error?.response?.status) {
      case 401:
        // clearSession();
        break;
      case 400:
        // toast.error(error.response.data.message);
        break;
      default:
      // toast.error('Something went wrong, please try again');
    }
    return Promise.reject(error);
  }
);
