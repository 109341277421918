import axios from "axios";
import {BASE_URL} from "../constants";

///page crud
export const getPages = (props) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/staticPage/listStaticPages`
    });
};
export function getPageBySlug(props){
    return axios({
        method: 'GET',
        params: props,
        url: `/staticPage/getStaticPageDetails`
    })
}
export const addStaticPage = (props) => {
    return axios({
        method: 'POST',
        data: props,
        url: `/staticPage/createStaticPage`
    });
};
export function editStaticPage(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: `/staticPage/updateStaticPage`
    })
}



