import JoditEditor from "jodit-react";
// import JoditEditor from "jodit-pro-react";
// import { useEffect, useRef, useState } from "react";
import { useController } from "react-hook-form";
import { errorType } from "../../constants";

function JoditEdior({ control, name, label, rules, defaultValue, errorClass }) {
  // local variables
  // const editor = useRef(null);
  // const [content, setContent] = useState("");

  // use form hooks
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    uploader: {
      url: "https://xdsoft.net/jodit/finder/?action=fileUpload",
    },
    filebrowser: {
      ajax: {
        url: "https://xdsoft.net/jodit/finder/",
      },
      height: 580,
    },
  };

  //   useEffect(() => {
  //     if (content !== "") field.onChange(content);
  //   }, [content]);

  return (
    <>
      <div className="form-group">
        {label && <label>{label}</label>}
        <div className="textfield-block">
          <JoditEditor
            value={field?.value}
            config={config}
            tabIndex={1}
            onBlur={(newContent) => field.onChange(newContent)}
            onChange={(newContent) => {
            }}
          />

          {errorType?.map((type) => {
            if (error?.type === type && error?.message !== "") {
              return (
                <span key={type} className={errorClass || "error-msg"}>
                  {error?.message}
                </span>
              );
            }
          })}
        </div>
      </div>
    </>
  );
}

export default JoditEdior;
