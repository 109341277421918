import axios from "axios";

export function login(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/user/signIn'
    })
}

// export function uploadFile(props) {
//     return axios({
//       method: 'POST',
//       data: props,
//       url: `/attachment/upload`
//     })
// };

// export function removeFile(uniqueName) {
//     return axios({
//       method: 'DELETE',
//       // params: props,
//       url: `attachment/`+uniqueName
//     })
// };

// for password setting 
// export function changePassword(payload) {
//   console.log("payload",payload)
//     return axios({
//         method: 'PATCH',
//         data: payload,
//         url: '/user/changePassword'
//     })
// };

// export function emailVerification(payload){
//   return axios({
//     method:'POST',
//     data:payload,
//     url:'/verifyEmail'
//   })
// }